import React, { useEffect, useState, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import WaveSurfer from "wavesurfer.js";
import { Pause, PlayArrow } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

const SongCard = ({ index, title, genre, url, currentSong, setCurrentSong }) => {
  let [isPlaying, setIsPlaying] = useState(false)
  let [waveSurfer, setWaveSurfer] = useState(null)

  const stopAudio = ()=>{
    if(waveSurfer){
      waveSurfer.stop();
    }
  }

  const playMain = ()=>{
    waveSurfer.load(`https://musiclibrarystorage.blob.core.windows.net/${genre}/${title}-${genre}.mp3`)
    waveSurfer.on('ready',()=>{
      waveSurfer.play()
      if (waveSurfer.isPlaying()) {
        setIsPlaying(true)
      } else {
        setIsPlaying(false)
      } 
    });
  
  }

  const togglePlayPause = () => {
    waveSurfer.playPause()
    if (waveSurfer.isPlaying()) {
      setIsPlaying(true)
    } else {
      setIsPlaying(false)
    }
  }
  const playShort = (version)=>{
    
    waveSurfer.load(`https://musiclibrarystorage.blob.core.windows.net/${genre}/${version}`);
    waveSurfer.on('ready',()=>{
      waveSurfer.play()
      if (waveSurfer.isPlaying()) {
        setIsPlaying(true)
      } else {
        setIsPlaying(false)
      }  
    });
   
  }

  useEffect(()=>()=>{
    stopAudio();
  },[waveSurfer]) 

  useEffect(() => {
    setWaveSurfer(WaveSurfer.create({
      container: `#waveform${index}`,
      minPxPerSec: 10,
      barWidth: 2,
      backgroundColor: '#bd6578',
      waveColor: 'white',
      height:60
    }))
  }, [])

  useEffect(()=>{
    if(currentSong != `waveform${index}`){
      setIsPlaying(false);
      stopAudio();
    }
  },[currentSong])

  if(isPlaying){
    setCurrentSong(`waveform${index}`);
  }

  if (!isPlaying && currentSong != `waveform${index}` && document.getElementById(`waveform${index}`)) {
     document.getElementById(`waveform${index}`).hidden=true
     document.getElementById(`waveform${index}info`).hidden=true
  } 
  if (isPlaying && document.getElementById(`waveform${index}`)) {
    document.getElementById(`waveform${index}`).hidden = false
    document.getElementById(`waveform${index}info`).hidden = false
    if (waveSurfer) {
      waveSurfer.drawBuffer();
    }
  }

  return (
    <div style={{ margin: '20px 0px', padding: '10px 10px' }}>
      <Accordion>
        <AccordionSummary>
          <Typography style={{marginRight:'10px'}} component="h5" variant="h5">
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            David Renda  <button className='btn' onClick={() => playMain()}> <PlayArrow /></button>
          </Typography>
        </AccordionSummary>
        
          {url.map(version=>{
            if(version.includes('short')){
              return (
              <AccordionDetails>
                {version.split("short")[1]} <button className='btn' onClick={() => {
                  playShort(version);
                }}> <PlayArrow /></button>
              </AccordionDetails>)
            }
          })} 
        
        
      </Accordion>
     
      <div id={`waveform${index}container`} style={{ backgroundColor: '#dbdbdb', position: 'fixed', bottom: '0px', left: '0px', zIndex:100}}>
        <div style={{ width: '100vw', zIndex: 1000 }} hidden id={`waveform${index}`}>

        </div>
        <div style={{padding:'10px'}} id={`waveform${index}info`} hidden>
          <Typography style={{fontSize:'100%'}} component="h5" variant="h5">
            {title}
          </Typography>
          <Typography style={{fontSize:'80%'}} variant="subtitle1" color="textSecondary">
            David Renda -  {genre} <button style={{padding: '0px'}} className='btn' onClick={() => togglePlayPause()}>{isPlaying ? <Pause /> : <PlayArrow />}</button>
          </Typography>
        </div>
      </div>
    </div>)
}

export default SongCard;