import React, { useState } from 'react'
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };

  return (
    <div className='headerContainer' >
      <div >
        <Link style={{fontSize:'120%'}} className='btnlink' to='/'>David Renda Music</Link>
      </div> 
      <div >
        <Link className='btnlink' to='/'>Contact us</Link>
      </div>  
    </div>
  )
}

export default Header;