import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import SongCard from "./SongCard";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Categories from './Categories.json';

const { SearchBar } = Search;
const axios = require('axios');
const convert = require('xml-js');


let final = [];

const Genre = ({ match }) => {

  const [musicLibrary, setMusicLibrary] = useState([])
  const [currentSong, setCurrentSong] = useState([])

  const products = [];

  useEffect(() => () => {
    final = [];
  }, [])

  // if(final.length > 1){
  //   final.forEach((song) => {
  //     if (song.length > 1) {
  //       song.forEach( version =>fetch(`https://musiclibrarystorage.blob.core.windows.net/${match.params.title.toLowerCase()}/${version}`))
  //     }
  //     else {
  //       fetch(`https://musiclibrarystorage.blob.core.windows.net/${match.params.title.toLowerCase()}/${song}`)
  //     }
  //   })
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get(`https://musiclibrarystorage.blob.core.windows.net/${match.params.title.toLowerCase()}?restype=container&comp=list`)
      .then(function (response) {
        // handle success
        const result = JSON.parse(convert.xml2json(response.data, { compact: true, spaces: 4 })).EnumerationResults.Blobs.Blob;
        if (result.length > 1) {
          setMusicLibrary(result.map((song) => { return song.Name._text }))
        } else setMusicLibrary([result.Name._text])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })

  }, [match.params])

  for (let i = 0; i < musicLibrary.length; i++) {
    let keyword = musicLibrary[i] ? musicLibrary[i].split('-')[0] : ''
    if (keyword != '') {
      let result = musicLibrary.filter(word => word.includes(keyword));
      result.forEach((item2) => {
        let index = musicLibrary.indexOf(item2);
        musicLibrary.splice(index, 1)
        i--;
      })
      if (result.length > 0) {
        final.push(result);
      }
    }
  }

  final.forEach((array, key) => {
    products.push({
      id: array[0].split('-')[0] + ' ' + match.params.title,
      song: <SongCard index={key} setCurrentSong={setCurrentSong} currentSong={currentSong} title={[array[0].split('-')[0]]} genre={match.params.title.toLowerCase()} url={array} />
    });
  })

  const columns = [{
    dataField: 'id',
    text: 'ID',
    hidden: true
  }, {
    dataField: 'song',
    text: ''
  }];

  return (
    <div className='page'>
      <div style={{ textAlign: 'center', margin: '15vh 0px 10vh 0px' }}>
        <img style={{ width: '300px', height: 'auto' }} alt={Categories[match.params.index].alt} className='img' src={Categories[match.params.index].img} />
      </div>
      <div style={{ marginBottom: '10vh' }}>
        <ToolkitProvider
          keyField="id"
          data={products}
          columns={columns}
          search
        >
          {
            props => (
              <div>
                <div id='tablee' style={{ textAlign: 'center' }}><SearchBar {...props.searchProps} /></div>
                <BootstrapTable
                  {...props.baseProps}
                />
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    </div>
  )
}

export default withRouter(Genre);