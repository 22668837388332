import React from 'react'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LibraryMusicOutlinedIcon from '@material-ui/icons/LibraryMusicOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';

const Footer = () =>{
    const date = new Date();
    
    return (
        <div className='footer'>
           <div className='footer-container'>
               <div className='row footer-center'>
                <div className='col'><LibraryMusicOutlinedIcon/></div>
                <div className='col'><SettingsOutlinedIcon/></div>
                <div className='col'><BookOutlinedIcon/></div>
               </div>
               <div className='row footer-center'>
                <div className='col'>
                        <p>Royalty Free Music</p>
                        <p>Hire Music Composer</p>
                        <p>Latest Music Additions</p>
                </div>
                <div className='col'>
                        <p>Contact us</p>
                        <p>Policy Agreement</p>
                        <p>Donate</p> 
                </div>       
                <div className='col'>
                        <p>Blog</p>
                </div>               
                </div>
           </div>
            <p style={{textAlign:'center', color:'white',bottom:'0px'}}> ©{date.getFullYear()} Renda Studios Inc. - Royalty Free Music And Sound Effects</p>
        </div>
    )
}

export default Footer;