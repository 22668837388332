import React from 'react'; 
import './style/style.css'
import {BrowserRouter as Router, Route} from "react-router-dom";
import Home from './components/Home/Home';
import Footer from './components/Footer/Footer';
import Genre from './components/Genre/Genre';
import Header from './components/Header/Header';

function App() {
  return (
    <>
      <Header/>
      <Route exact path='/' component={Home} />
      <Route exact path='/genre/:title/:index' component={Genre} />
      <Footer/>
    </>
  );
}

export default App;
