import React, { useState } from 'react'
import { Link } from "react-router-dom";
import Categories from '../Genre/Categories.json';

// const Tag = ()=>{
//   // awdawd
//   return(
//     <>
//     </>
//   )
// }

const Home = (props) => {
  const {tag:Tag} = props;
  const [find,setFind] = useState();
  // Make a request for a user with a given ID

  // const data = () => (
  //   musicLibrary.map((song, index) => ({
  //     song: <SongCard title={[song.split('-')[0]]} genre={[song.split('-')[1].split('.')[0]]} url={song} />,
  //   }))
  // )
  return (
    <div >
      <div style={{ textAlign: 'center', marginBottom:'100px'}}>
        <img alt='Renda Studios logo' width={'35%'} src='/imgs/logo.png' />
      </div>
     
      <div className='container' style={{ marginBottom: '10vh',textAlign: 'center' }}>
        <input type='text' placeholder='search' onChange={(e)=>{
          Categories.map((category,key)=>{
            setFind(e.target.value.toUpperCase());
            console.log((category.title.toUpperCase().match(find)?category.title.toUpperCase().match(find).input:null) !== null)
          })
        }}/>
        <div style={{marginTop:'10vh'}} className='row'>
          {Categories.map((category,key) => {
            return(
              <div key={key} hidden={!((category.title.toUpperCase().match(find)?category.title.toUpperCase().match(find).input:null) !== null)} style={{margin:'20px 0px'}} className='col-md'>
                <Link className='btnlink' to={'genre/' +category.title +'/'+category.index}>
                  < h2 > { category.title }</h2>
                  <img style={{ width: '200px', height: '133px' }} alt={category.alt} src={category.img} />
                </Link>
              </div>
          )})}
        </div>
      </div>
    </div >

  )
}

export default Home;